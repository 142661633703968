import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/Sidebar.vue'),
    children:[
      {
        path:'/article/CreateArticle',
        component: () => import('../views/article/CreateArticle'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/article/ListArt',
        component: () => import('../views/article/ListArt'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
    ]
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/Sidebar.vue'),
    children:[
      {
        path:'/tasks/queuedTask',
        component: () => import('../views/tasks/queuedTask'),
      },
      {
        path:'/tasks/CustomerVoucher',
        component: () => import('../views/tasks/CustomerVoucher'),
      },
      // {
      //   path:'/home/testTable',
      //   component: () => import('../views/tasks/testTable'),
      // },
    ]
  },
  // 爬取网站测试
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Sidebar.vue'),
    children:[
      {
        path:'/test/getWebSite',
        component: () => import('../views/test/getWebSite'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/CustomerList',
        component: () => import('../views/test/CustomerList'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/RegList',
        component: () => import('../views/test/RegList'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/RegListSaiHan',
        component: () => import('../views/test/RegListSaiHan'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/RegListFangZhan',
        component: () => import('../views/test/RegListFangZhan'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/RegListFangZhanAll',
        component: () => import('../views/test/RegListFangZhanAll'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      },
      {
        path:'/test/RegListTeach',
        component: () => import('../views/test/RegListTeach'),
        meta: { requiresAuth: true }, // 定义需要登录才能访问的路由
      }

    ]
  },
]

const router = new VueRouter({
  // history刷新404
  // mode: 'history', 
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫 获知当前选中的左侧菜单
router.beforeEach((to, from, next) => {
  const activeIndex = to.path; // 获取当前路由路径
  // 将 activeIndex 存储到本地存储或 Vuex 中 刷新页面选中上次左侧菜单
  localStorage.setItem('activeIndex', activeIndex);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果该路由需要登录才能访问，则判断用户是否已经登录
    if (!isAuthenticated()) {
      // 如果用户没有登录，则跳转到登录页面
      alert('登录信息已失效，请重新登录');
      next('/');
    } else {
      // 用户已登录，允许继续访问
      next();
    }
  } else {
    // 如果该路由不需要登录，则直接允许访问
    next();
  }
});

// 判断用户是否已经登录的代码逻辑
function isAuthenticated() {
  const user = JSON.parse(localStorage.getItem('user')); // 从本地存储中获取用户信息
  return user && user[0].username; // 判断用户信息中是否包含 username 字段来确认是否登录
}

export default router
