const axios = require('axios');
const { Loading, Message } = require('element-ui');

const service = axios.create({
  // baseURL: 'http://localhost:3000',
  // 线上环境使用
  baseURL: 'http://62.234.192.148:3000',
  timeout: 100000 
});


// const service = axios.create({
//   // baseURL: '/api',
//   // 本地开发环境用这个
//   baseURL: 'http://localhost:3000',
//   //线上环境
//   // baseURL: 'http://62.234.192.148:3000',
//   // baseURL: 'https://www.donggg.cloud:3000',
//   timeout: 100000 
// });



// 全局请求 loading封装
let loadingInstance = {
  instance: null,
  text: "数据加载中..."
}; 

service.interceptors.request.use((config) => {
  loadingInstance.instance = Loading.service({
    text: loadingInstance.text,
    background: 'rgba(0, 0, 0, 0.7)'
  });
  return config;
}, (error) => {
  return Promise.reject(error);
});

service.interceptors.response.use((response) => {
  loadingInstance.instance.close();
  return response;
}, (error) => {
  loadingInstance.instance.close();

  if (error.code === 'ERR_NETWORK' && error.message.includes('timeout')) {
    error.customMessage = '请求超时，请检查您的网络连接';
  } else if (error.response && error.response.status >= 500) {
    error.customMessage = '服务器错误，请稍后重试';
  } else if (error.response && error.response.status === 400) {
    error.customMessage = '账号或密码错误，请检查后重新输入';
  } else {
    error.customMessage = error.message || '请求失败，请稍后重试';
  }

  Message.error(error.customMessage);
  return Promise.reject(error);
});

module.exports = service;












// // axios封装配置   原版代码
// const axios = require('axios');
// const { Loading } = require('element-ui');

// const service = axios.create({
//   // baseURL: '/api',
//   // 本地开发环境用这个
//   baseURL: 'http://localhost:3000',
//   //线上环境
//   // baseURL: 'http://62.234.192.148:3000',
//   // baseURL: 'https://www.donggg.cloud:3000',
//   timeout: 100000 
// });

// // 全局请求 loading封装
// let loadingInstance = {
//   instance: null,
//   text:"数据加载中..."
// }; 

// service.interceptors.request.use((config) => {
//   // 在请求发送前进行一些操作，如添加loading效果等
//   loadingInstance.instance = Loading.service({
//     text: loadingInstance.text,
//     background: 'rgba(0, 0, 0, 0.7)' // 设置loading的背景颜色 
//   });
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

// service.interceptors.response.use((response) => {
//   // 在响应返回后进行一些操作，如关闭loading效果等
//   loadingInstance.instance.close();
//   // return response.data;
//   return response;
// }, (error) => {
//   console.log(error,"error错误提示");
//   if (error.code === 'ERR_NETWORK' && error.message.includes('timeout')) {
//     const timeoutError = new Error('请求超时，请检查您的网络连接');
//     return Promise.reject(timeoutError);
//   }

//   loadingInstance.instance.close();
//   return Promise.reject(error);

// });

// // loading封装end


// module.exports = service;























