import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

// import auth from './utils/auth.js';
import './utils/auth.js'; // 确保 auth.js 被执行即可  长时间未操作逻辑

//全局修改element默认配置  全局设置表格内容超出显示   showOverflowTooltip
ElementUI.TableColumn.props.showOverflowTooltip ={type:Boolean, default: true}

Vue.config.productionTip = false

Vue.use(ElementUI);



// 定义全局过滤器
Vue.filter('formatDate', function(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
});





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
