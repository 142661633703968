

// src/utils/auth.js
import { MessageBox } from 'element-ui';

let lastInteractionTime = new Date().getTime();
const TIMEOUT = 60 * 60 * 1000; // 60分钟
// const TIMEOUT = 15 * 1000; // 15秒

function resetLastInteractionTime() {
  lastInteractionTime = new Date().getTime();
}

function checkTimeout() {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - lastInteractionTime;
  
  // 获取当前页面路径
    // const currentPath = window.location.pathname;
    const currentHash = window.location.hash;

  // 只有在非登录页和首页的时候才检查超时
  if (currentHash !== '#/' && timeDiff > TIMEOUT) {
    // 超时处理逻辑，比如跳到登录页面
    MessageBox.alert('长时间未操作，请重新登录', '提示', {
      confirmButtonText: '确定',
      showClose: false,
      type: 'warning',
      callback: () => {
        localStorage.removeItem('user'); // 清除存储的用户信息
        // window.location.href = '/login'; // 跳转到登录页面
        window.location.href = '/'; // 跳转到首页登录

      }
    });
  }
}

// 确保只绑定一次事件监听器和定时器
let authInitialized = false;

function initializeAuth() {
  if (!authInitialized) {
    // 监听用户操作事件
    window.addEventListener('click', resetLastInteractionTime);
    window.addEventListener('scroll', resetLastInteractionTime);
    window.addEventListener('mousemove', resetLastInteractionTime);
    window.addEventListener('keydown', resetLastInteractionTime);

    // 定时检测是否超时
    setInterval(checkTimeout, 1000);

    authInitialized = true;
  }
}

initializeAuth();

export default {
  resetLastInteractionTime,
  checkTimeout
};














// 长时间未操作 逻辑处理
// src/utils/auth.js
// import { MessageBox } from 'element-ui';

// let lastInteractionTime = new Date().getTime();
// // const TIMEOUT = 15 * 1000; // 15秒
// const TIMEOUT = 60 * 60 * 1000; // 60分钟

// function resetLastInteractionTime() {
//   lastInteractionTime = new Date().getTime();
// }

// function checkTimeout() {
//   const currentTime = new Date().getTime();
//   const timeDiff = currentTime - lastInteractionTime;
  
//   if (timeDiff > TIMEOUT) {
//     // 超时处理逻辑，比如跳到登录页面
//     MessageBox.alert('长时间未操作，请重新登录', '提示', {
//       confirmButtonText: '确定',
//       showClose: false,
//       type: 'warning',
//       callback: () => {
//         localStorage.removeItem('user'); // 清除存储的用户信息
//         window.location.href = '/login'; // 跳转到登录页面
//       }
//     });
//   }
// }

// // 确保只绑定一次事件监听器和定时器
// let authInitialized = false;

// function initializeAuth() {
//   if (!authInitialized) {
//     // 监听用户操作事件
//     window.addEventListener('click', resetLastInteractionTime);
//     window.addEventListener('scroll', resetLastInteractionTime);
//     window.addEventListener('mousemove', resetLastInteractionTime);
//     window.addEventListener('keydown', resetLastInteractionTime);

//     // 定时检测是否超时
//     setInterval(checkTimeout, 1000);

//     authInitialized = true;
//   }
// }

// initializeAuth();

// export default {
//   resetLastInteractionTime,
//   checkTimeout
// };



